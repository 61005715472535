@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 640px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .card-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .card {
    max-width: 400px;
    margin: 0 auto;
  }