@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

.navbar {
  position: sticky;
  background: #143f83;
  color: #ffffff;
  top: 0;
  transition: background-color 0.5s ease;
  z-index: 999;
}

.navbar ul li {
  color: white;
}

.navbar ul li {
  position: relative;
}


.navbar ul li.nav-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: blue;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}

.navbar ul li.nav-link:hover::before {
  width: 100%;
  visibility: visible;
}

.navbar ul li.nav-link.active::before {
  width: 100%;
  visibility: visible;
}

.navbar ul li.nav-link.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: blue;
  visibility: visible;
  transition: width 0.3s ease-in-out;
}

.navbar ul li.nav-link-white::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: white;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}

.navbar ul li.nav-link-white:hover::before {
  width: 100%;
  visibility: visible;
}

.navbar ul li.nav-link-white.active::before {
  width: 100%;
  visibility: visible;
}

.navbar ul li.nav-link-white.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: white;
  visibility: visible;
  transition: width 0.3s ease-in-out;
}

.bg-header {
  border-radius: 40px;
  width: 100%;
}

.navbar.actived ul li,
.navbar.actived span {
  color: blue;
}

.actived.btn-actived {
  color: #3c37f1;
}

.navbar.actived {
  background-color: #ffffff;
  transition: background-color 0.5s ease;
}

.bg-image {
  background-image: url(../assets/bg-home.png);
  background-size: cover;
  width: 100%;
  height: 638px;
}

.rounded-extra {
  border-radius: 30px;
}

.card-profile {
  height: 320px;
}

.card-profile p {
  line-height: 170%;
  /* font-size: 23px; */
}

.bg-service {
  background-image: url(../assets/bg-header.png);
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-dt {
  background-image: url(../assets/bg-dt.png);
}

.bg-et {
  background-image: url(../assets/bt-et.png);
}

.box-sw {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
}
.bg-linear {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(239.98deg, #246bdd 28.01%, #0f1b33 81.41%);
}

.client-box {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.text-team {
  line-height: 120%;
}

.card-our-team {
  width: 340px;
  height: 300px;
  background-color: #1e3158;
  border-radius: 30px;
  border: 1px solid gray;
}

.card-our-teams {
  width: 340px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 30px;
  border: 1px solid gray;
}

.question-header {
  transition: background-color 0.3s;
}

.question-header:hover {
  background-color: #f3f4f6;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content.open {
  max-height: 1000px;
}

@media screen and (min-width: 240px) and (max-width: 320px) {
  .event-mt {
    margin-top: 200px;
  }
  .consulting-mt {
    margin-top: -50px;
  }
  .edu-mt {
    margin-top: -180px;
  }
  .service-mt,
  .home-mt,
  .team-mt,
  .support-mt {
    margin-top: 70px;
  }
}
